<!-- <nav>
    <div class="container-nav">
    <div class="logo">
        <img [routerLink]="['/']" src="./../../../assets/img/LOGO-1.png" alt="MIRA">
       
    </div>
    <ul>
        <li>
            <a class="menu-item" href="/por-que-mira" routerLinkActive="active" [routerLinkActiveOptions]="myMatchOptions">¿Por qué ©MIRA?</a>
        </li>
        <li>
            <a class="menu-item" href="/arquitectura" routerLinkActive="active" [routerLinkActiveOptions]="myMatchOptions">Arquitectura</a>
        </li>
        <li>
            <a class="menu-item" href="/nuevas-funcionalidades" routerLinkActive="active" [routerLinkActiveOptions]="myMatchOptions">Nuevas funcionalidades</a>
        </li>
        <li>
            <a class="menu-item" href="/nuestras-oficinas" routerLinkActive="active" [routerLinkActiveOptions]="myMatchOptions">Nuestras oficinas</a>
        </li>
    </ul>
    <ul>
        <li>
            <a class="youtube" href="https://www.youtube.com/@coloniacompany" target="_blank">
                <img src="./../../../assets/img/ico-youtube.png" alt="Youtube">
            </a>
        </li>
        <li>
            <a class="linkedin" href="https://www.linkedin.com/showcase/miraladata" target="_blank">
                <img src="./../../../assets/img/ico-linkedin.png" alt="Linkedin">
            </a>
        </li>
        <li *ngIf="!url.includes('ingreso')">
            <a mat-raised-button color="accent" class="btn-contact" [routerLink]="'/ingreso'">
            INGRESAR
            </a>
        </li>
        <li *ngIf="url.includes('ingreso')">
            <a mat-raised-button color="accent" class="btn-contact" [routerLink]="'/registro'">
            REGISTRARSE
            </a>
        </li>
    </ul>
    </div>
</nav> -->

<header class="header">
    <div class="header__container">
        <!-- Logo -->
        <img [routerLink]="['/']" src="./../../../../assets/img/LOGO-WEB-BLANCO.png" alt="Logo" class="header__logo" />


        <div class="navigation">
            <!-- Navigation -->
            <nav class="header__nav">
                <a href="/nuestros-productos" class="header__link">Nuestros productos</a>
                <a href="/nuestras-oficinas" class="header__link">Nuestras oficinas</a>
            </nav>

            <!-- Action Button -->
            <button mat-raised-button class="header__button" [routerLink]="'/ingreso'">Ingresar</button>

            <!-- Mobile Menu Icon -->
            <button class="header__menu-icon" (click)="toggleMenu()">
                <mat-icon>{{ menuOpen ? 'close' : 'menu'}}</mat-icon>
            </button>
        </div>

    </div>

    <!-- Mobile Dropdown Menu -->
    <div class="header__mobile-menu" *ngIf="menuOpen">
        <a href="/nuestros-productos" class="header__mobile-link">Nuestros productos</a>
        <a href="/nuestras-oficinas" class="header__mobile-link">Nuestras oficinas</a>
        <button class="header__mobile-button" [routerLink]="'/ingreso'">Ingresar</button>
    </div>
</header>


<!-- <header class="header">
    <div class="header__logo">
        <img src="./../../../../assets/img/LOGO-WEB-BLANCO.png" alt="MIRA Logo" class="header__logo-img" />
    </div>
    <div class="header__left">
        <button mat-button>Nuetros Productos</button>
        <button mat-button>Nuestras Oficinas</button>
    </div>

    <div class="header__right">
        <button mat-icon-button>
        <mat-icon>youtube</mat-icon>
      </button>
        <button mat-icon-button>
        <mat-icon>linkedin</mat-icon>
      </button>
        <button mat-raised-button color="primary">Ingresar</button>
    </div>
</header> -->