<ng-container *ngIf="loaded">
    <ng-container *ngIf="url.includes('privado'); then private; else public">
    </ng-container>

    <ng-template #private>
        <div class="private-container">
            <app-private>
                <router-outlet></router-outlet>
            </app-private>
        </div>

    </ng-template>

    <ng-template #public>
        <app-nav [transparent]="navTransparent" [url]="url"></app-nav>
        <router-outlet (activate)="onActivate($event)"></router-outlet>
        <app-footer></app-footer>
        <!-- <ng-container *ngIf="isMobile else desktop">
            <app-sidenav [transparent]="navTransparent" [url]="url">
                <router-outlet (activate)="onActivate($event)"></router-outlet>
            </app-sidenav>
        </ng-container>

        <ng-template #desktop>
            <app-nav [transparent]="navTransparent" [url]="url"></app-nav>
            <router-outlet (activate)="onActivate($event)"></router-outlet>
            <app-footer></app-footer>
        </ng-template> -->
    </ng-template>
</ng-container>