export const environment = {
  production: true,
  siteKey: '6LfTDOEpAAAAACYCgfqWoYRn_3PmGGlOXrHaWOjS',
  api: 'https://mira-dot-miraladata.ue.r.appspot.com/api',
  apiMira: 'https://mira-dot-miraladata.ue.r.appspot.com/api',
  apiBio: 'https://bioapi-4x43nmphxa-ue.a.run.app',
  millisecondsListaChat: 60000,
  bucketSoporte: 'repo-proyectos',
  namePlatform: 'MIRA',
  iIdRolDoctorCliente: 'df52abb3-1082-4950-8798-24c1685d8ef5',
};
