<!-- <section class="features">
    <div class="features__header">
        <h2>Conectamos Empresas y Personas</h2>
        <p>
            Potencia el acceso a tus servicios digitales y fortalece la relación con tus clientes, asegurando una experiencia segura y confiable.
        </p>
    </div>
    <div class="features__content">
        <div class="features__image">
            <img src="./../../../../assets/img/sujeto.png" alt="Hombre viendo su teléfono" />
        </div>
        <div class="features__cards">
            <mat-card class="feature-card" *ngFor="let item of features">
                <mat-icon>check_circle</mat-icon>
                <p>{{ item }}</p>
            </mat-card>
        </div>
    </div>
</section> -->

<section class="features">
    <div class="features__header">
        <h2>Conectamos Empresas y Personas</h2>
        <p>
            Potencia el acceso a tus servicios digitales y fortalece la relación con tus clientes, <br> asegurando una experiencia segura y confiable.
        </p>
    </div>
    <div class="features__content">
        <div class="features__image-container">

            <!-- Cards -->
            <div class="feature-card" *ngFor="let item of features; let i = index" [class]="'feature-card position-' + i">
                <div class="img-feature">
                    <img [src]="'./../../../../assets/img/productos/'+(i+1)+'.png'" alt="">
                    <mat-icon>check_circle</mat-icon>
                </div>
                <p>{{ item }}</p>
            </div>

            <img src="./../../../../assets/img/sujeto-02.png" alt="Hombre viendo su teléfono" class="features__image" />
        </div>
    </div>
</section>