/* import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID, AfterViewInit, ElementRef } from '@angular/core';
import { Event, NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { IRouteData, Layout } from 'lib-shared/interfaces';
import { LanguagesService } from 'lib-shared/services/languages';
import { Observable, distinctUntilChanged, filter, map } from 'rxjs'; */

import { CommonModule, isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, Inject, PLATFORM_ID } from '@angular/core';
import { Event, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { LanguagesService } from 'lib-shared/services/languages';
import { NavComponent } from './shared/components/nav/nav.component';
import { SideNavComponent } from './shared/components/sidenav/sidenav.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { PrivateComponent } from 'lib-shared/layouts';
import { HttpClientModule } from '@angular/common/http';
import { AuthService } from 'lib-shared/store';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet, 
    HttpClientModule,
    CommonModule,
    NavComponent,
    SideNavComponent,
    FooterComponent,
    PrivateComponent,
  ],
  templateUrl: './app.component.html',
})
export class AppComponent implements AfterViewInit {
  navTransparent: boolean = false;
  isMobile: boolean = false;
  loaded: boolean = false;
  url: string = '';
  /* data$: Observable<IRouteData>;
  layout$: Observable<Layout>;
  layout: any; */
  
  constructor(private router: Router, private langSv: LanguagesService, private authSv: AuthService, @Inject(PLATFORM_ID) private plattformId: any) {
    /* this.data$ = this.router.events.pipe(
      filter((e: any) => e.snapshot != undefined),
      map((e: any) => {
        console.log(e)
        return (e.snapshot.data != null ? e.snapshot.data : {})
      })
    );

    this.layout$ = this.data$.pipe(
      map(data => data.layout!),
      distinctUntilChanged()
    );

    this.layout$.subscribe(l => {
      this.layout = l;
      console.log(l)
    }) */

    this.router.events.subscribe((r: Event) => {
      if (r instanceof NavigationEnd) {
        this.url = r.url;
        //this.navTransparent = (r.url == '/' );
      }
    });
  }

  ngAfterViewInit(): void {
    if(isPlatformBrowser(this.plattformId)) {
      this.authSv.saveSiWeb(10);
      this.langSv.setLang();
      this.loaded = true;
      this.isMobile = window.matchMedia("(max-width: 990px)").matches;
    }
   /*  var v = document.createElement("script");
    v.type = "text/javascript";
    v.innerHTML = "function googleTranslateElementInit() { new google.translate.TranslateElement({ pageLanguage: 'es', includedLanguages: 'en,es', autoDisplay: true, }, 'google_translate_element'); } ";
    this.elementRef.nativeElement.appendChild(v);
    var s = document.createElement("script");
    s.type = "text/javascript";
    s.src = "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"; */
  }

  onActivate(event) {
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
 }
}