import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

import { provideHttpClient, withFetch, HTTP_INTERCEPTORS } from '@angular/common/http';
import { effectsArr, LibStoreModule, REDUCERS } from 'lib-shared/store';
import { environment } from '../environments/environment';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { TokenInterceptor } from 'lib-shared/interceptors';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes), 
    provideClientHydration(), 
    provideAnimationsAsync(),
    /* provideStore(), provideEffects(),  */
    provideHttpClient(withFetch()),
    importProvidersFrom(
      LibStoreModule.forRoot(environment),
      StoreModule.forRoot(REDUCERS, {
        runtimeChecks: {
          strictStateImmutability: true,
          strictActionImmutability: true
        }
      }),
      EffectsModule.forRoot(effectsArr),
    ),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ]
};
