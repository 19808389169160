import { CommonModule } from '@angular/common';
import { Component, ElementRef } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [
    MatButtonModule,
    MatIconModule,
    RouterModule,
    CommonModule,
  ],
  template: `
    <footer>
  <div class="footer-container">
    <div class="logo-section">
      <img src="./../../../assets/img/LOGO-WEB-BLANCO.png" alt="MIRA Logo" />
      <p>
        ©MIRA es una plataforma SaaS de Colonia Company con partida registral de PI # 02746-2023
      </p>
      <p>
        ©Colonia Company {{ today | date: 'yyyy' }}. A Data Science Company. <br />
        Av. El Derby 055 Edificio Cronos Torre 1, Piso 7. Santiago de Surco, Lima - Perú.
      </p>
    </div>

    <div class="contact-section">
      <h4>Conversemos</h4>
      <div class="contact-data">
        <p><i class="material-icons">mail_outline</i><a href="mailto:info@colonia.company">info&#64;colonia.company</a></p>
        <p><i class="material-icons">phone</i><a href="tel:+51904448143">+51 904 448 143</a></p>
      </div>
    </div>

    <div class="translate-section">
      <h4>Traducción</h4>
      <div id="google_translate_element"></div>
    </div>
  </div>
</footer>

  `,
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

    today = new Date();

    constructor(private elementRef: ElementRef) {}

    ngAfterViewInit(): void {
      /* var v = document.createElement("script");
      v.type = "text/javascript";
      v.innerHTML = "function googleTranslateElementInit() { new google.translate.TranslateElement({ pageLanguage: 'es', includedLanguages: 'en,es', autoDisplay: true, }, 'google_translate_element'); } ";
      this.elementRef.nativeElement.appendChild(v);
      var s = document.createElement("script");
      s.type = "text/javascript";
      s.src = "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
      console.log(this.elementRef) */
    }

}