import { Component, OnInit} from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { SEOService } from 'src/app/shared/services/seo.service';

@Component({
  selector: 'app-our-offices',
  standalone: true,
  imports: [
    MatCardModule,
    MatIconModule
  ],
  templateUrl: './our-offices.component.html',
  styleUrls: ['./our-offices.component.scss']
})
export class OurOfficesComponent implements OnInit {

  constructor(private seoSv: SEOService) {
    this.seoSv.setPageTitle("Nuestras oficinas");
    this.seoSv.updateCanonicalUrl('Nuestras oficinas');
  }

  ngOnInit(): void {
  }
   
}
