import { AfterViewInit, Component, Inject, OnInit, PLATFORM_ID, } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
/* import { MatDialog, MatDialogModule } from '@angular/material/dialog'; */
import { VideoComponent } from './modals/video/video.component';
import { MatCardModule } from '@angular/material/card';
import { CommonModule, isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    MatButtonModule,
    MatIconModule,
    MatTabsModule,
    MatCardModule,
    CommonModule
    /* MatDialogModule */
  ],
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit {

  isMobile: boolean = false;

  constructor( @Inject(PLATFORM_ID) private plattformId: any/* private dialog: MatDialog */) {}

  ngOnInit(): void {
    /* this.dialog.open(VideoComponent, {width: '100%'}).afterClosed().subscribe((result: any) => {

    }); */

    let video = document.getElementById('video');
    let alto = video.offsetWidth * 315 / 560;

    // Cambiar la altura del elemento
    video.style.height = alto + 'px';
  }

  ngAfterViewInit(): void {
    if(isPlatformBrowser(this.plattformId)) {
      this.isMobile = window.matchMedia("(max-width: 990px)").matches;
    }
  }
}
