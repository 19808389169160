import { CommonModule } from '@angular/common';
import { Component, OnInit} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { SEOService } from 'src/app/shared/services/seo.service';

@Component({
  selector: 'app-products',
  standalone: true,
  imports: [
    MatIconModule,
    CommonModule
  ],
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {

  features: string[] = [
    'Chatbots de Whatsapp',
    'Agentes autónomos',
    'Validación de documentos',
    'Ventas automatizadas',
    'Reconocimiento facial',
    'Firma electrónica',
    'Verificación de antecedentes',
  ];

  constructor(private seoSv: SEOService) {
    this.seoSv.setPageTitle("Nuestros productos");
    this.seoSv.updateCanonicalUrl('nuestros-productos');
  }

  ngOnInit(): void {
  }
   
}
