import { Routes } from '@angular/router';
import { HomeComponent } from './pages/public/home/home.component';
import { ArchitectureComponent } from './pages/public/architecture/architecture.component';
import { UseCasesComponent } from './pages/public/use-cases/use-cases.component';
import { BenefitsComponent } from './pages/public/benefits/benefits.component';
import { PricingComponent } from './pages/public/pricing/pricing.component';
import { WhyMIRAComponent } from './pages/public/why-mira/why-mira.component';
import { OurOfficesComponent } from './pages/public/our-offices/our-offices.component';
import { NewFeaturesComponent } from './pages/public/new-features/new-features.component';
import { ProductsComponent } from './pages/public/products/products.component';

export const routes: Routes = [
    {
      path: '',
      component: HomeComponent
    },
    {
      path: 'ingreso',
      loadChildren: () => import('./pages/public/login/login.module').then(m => m.LoginPageModule) 
    },
    {
      path: 'registro',
      loadChildren: () => import('./pages/public/sign-up/sign-up.module').then(m => m.SignUpPageModule) 
    },
    {
      path: 'recuperar-clave',
      loadChildren: () => import('./pages/public/recover-password/recover-password.module').then(m => m.RecoverPasswordPageModule) 
    },
    /* {
      path: 'por-que-mira/.',
      component: WhyMIRAComponent
    },
    {
      path: 'por-que-mira',
      component: WhyMIRAComponent
    },
    {
      path: 'arquitectura/.',
      component: ArchitectureComponent
    },
    {
      path: 'arquitectura',
      component: ArchitectureComponent
    },
    {
      path: 'casos-de-uso/.',
      component: UseCasesComponent
    },
    {
      path: 'casos-de-uso',
      component: UseCasesComponent
    },
    {
      path: 'beneficios/.',
      component: BenefitsComponent
    },
    {
      path: 'beneficios',
      component: BenefitsComponent
    }, */
    {
      path: 'nuestras-oficinas/.',
      component: OurOfficesComponent
    },
    {
      path: 'nuestras-oficinas',
      component: OurOfficesComponent
    },
    /* {
      path: 'precios/.',
      component: PricingComponent
    },
    {
      path: 'precios',
      component: PricingComponent
    },
    {
      path: 'nuevas-funcionalidades/.',
      component: NewFeaturesComponent
    },
    {
      path: 'nuevas-funcionalidades',
      component: NewFeaturesComponent
    }, */
    {
      path: 'nuestros-productos/.',
      component: ProductsComponent
    },
    {
      path: 'nuestros-productos',
      component: ProductsComponent
    },
    {
      path: 'privado',
      data: { layout: 'private' },
      children: [
        {
          path: '',
          redirectTo: 'principal',
          pathMatch: 'full'
        },
        {
          path: 'principal',
          loadChildren: () => import('./pages/private/main/main.module').then(m => m.MainModule)
        },
        {
          path: 'roles',
          loadChildren: () => import('./pages/private/roles/roles.module').then(m => m.RolesModule)
        },
        {
          path: 'usuarios',
          loadChildren: () => import('./pages/private/usuarios/usuarios.module').then(m => m.UsuariosModule)
        },
        {
          path: 'seguridad',
          loadChildren: () => import('./pages/private/seguridad/seguridad.module').then(m => m.SeguridadModule)
        },
        {
          path: 'atencionalcliente',
          loadChildren: () => import('./pages/private/atencion-cliente/atencion-cliente.module').then(m => m.AtencionClienteModule)
        },
        {
          path: 'configuracion/clases-genericas',
          loadChildren: () =>
            import('./pages/private/clases-genericas/clases-genericas.module').then(m => m.ClasesGenericasModule)
        },
        {
          path: 'data/discovery',
          loadChildren: () =>
            import('./pages/private/data-discovery/data-discovery.module').then(m => m.DataDiscoveryModule)
        },
        {
          path: 'data/audit',
          loadChildren: () =>
            import('./pages/private/audit-log/audit-log.module').then(m => m.AuditLogModule)
        },
        {
          path: 'monitoreo/log',
          loadChildren: () =>
            import('./pages/private/system-log/system-log.module').then(m => m.SystemLogModule)
        },
        {
          path: 'plantilla-datos',
          loadChildren: () =>
            import('./pages/private/plantillas-datos/plantillas-datos.module').then(m => m.PlantillasDatosModule)
        },
        {
          path: 'clusterizacion',
          loadChildren: () =>
            import('./pages/private/clusterizacion/clusterizacion.module').then(m => m.ClusterizacionModule)
        },
        {
          path: 'IA',
          loadChildren: () =>
            import('./pages/private/modelos-ia/modelos-ia.module').then(m => m.ModelosIAModule)
        },
        /* {
          path: 'fichas',
          loadChildren: () =>
            import('./pages/private/fichas/fichas.module').then(m => m.FichasModule)
        }, */
        {
          path: 'data/collections',
          loadChildren: () =>
            import('./pages/private/data-collections/data-collections.module').then(m => m.DataCollectionsModule)
        },
        {
          path: 'protocolos/grupo-pruebas',
          loadChildren: () =>
            import('./pages/private/grupos-pruebas/grupos-pruebas.module').then(m => m.GruposPruebasModule)
        },
        {
          path: 'protocolos/reglas-de-proceso',
          loadChildren: () =>
            import('./pages/private/catalogo-documentos-excepcion/catalogo-documentos-excepcion.module').then(m => m.CatalogoDocumentosExcepcionModule)
        },
        {
          path: 'protocolos/catalogo-excepcion',
          loadChildren: () =>
            import('./pages/private/catalogo-excepciones/catalogo-excepciones.module').then(m => m.CatalogoExcepcionesModule)
        },
        {
          path: 'protocolos/documentos',
          loadChildren: () =>
            import('./pages/private/subclases-documentos/subclases-documentos.module').then(m => m.SubClasesDocumentosModule)
        },
        {
          path: 'protocolos/clases-documentos',
          loadChildren: () =>
            import('./pages/private/clases-documentos/clases-documentos.module').then(m => m.ClasesDocumentosModule)
        },
        {
          path: 'protocolos/gastos-administrativos',
          loadChildren: () =>
            import('./pages/private/conceptos-facturables/conceptos-facturables.module').then(m => m.ConceptosFacturablesModule)
        },
        {
          path: 'protocolos/catalogo-pruebas',
          loadChildren: () =>
            import('./pages/private/catalog/catalog.module').then(m => m.CatalogModule)
        },
        {
          path: 'protocolos/fichas-medicas',
          loadChildren: () =>
            import('./pages/private/fichas/fichas.module').then(m => m.FichasModule)
        },
        {
          path: 'protocolos/tipo-aptitud',
          loadChildren: () =>
            import('./pages/private/tipos-aptitud/tipos-aptitud.module').then(m => m.TiposAptitudModule)
        },
        {
          path: 'protocolos/tipo-protocolo',
          loadChildren: () =>
            import('./pages/private/tipos-examen/tipos-examen.module').then(m => m.TiposExamenModule)
        },
        {
          path: 'protocolos/servicios',
          loadChildren: () =>
            import('./pages/private/servicios/servicios.module').then(m => m.ServiciosModule)
        },
        {
          path: 'empresa/holding',
          loadChildren: () =>
            import('./pages/private/holdings/holdings.module').then(m => m.HoldingsModule)
        },
        {
          path: 'empresa/crear',
          loadChildren: () =>
            import('./pages/private/crear-empresa/crear-empresa.module').then(m => m.CrearEmpresaModule)
        },
        {
          path: 'empresa/gestion',
          loadChildren: () =>
            import('./pages/private/empresas/empresas.module').then(m => m.EmpresasModule)
        },
        {
          path: 'plantillademensajes/gestion',
          loadChildren: () =>
            import('./pages/private/plantilla-mensajes/plantilla-mensajes.module').then(m => m.PlantillaMensajesModule)
        },
        {
          path: 'protocolos/clinicas',
          loadChildren: () =>
            import('./pages/private/sedes/sedes.module').then(m => m.SedesModule)
        },
        {
          path: 'protocolos/clinicas-especialistas-medicos',
          loadChildren: () =>
            import('./pages/private/especialistas-medicos/especialistas.module').then(m => m.EspecialistasModule)
        },
      ]}
    ];
