<section class="hero">
    <!-- Background Lines -->
    <div class="hero__background-lines"></div>

    <!-- Content Section: Text and Button -->
    <div class="hero__content">
        <h1 class="hero__title">
            Mira 3.0: Agentes Inteligentes Autónomos
        </h1>
        <p class="hero__description">
            Anticipa, entiende y resuelve desafíos de manera autónoma, <br> impulsando una transformación disruptiva en la estrategia <br> y los resultados de tu organización.
        </p>
        <a mat-raised-button color="primary" class="hero__button" href="https://calendar.app.google/RJVuoA9s9jeUH1TFA" target="_blank">
        Solicitar una prueba gratis
        </a>
    </div>

    <!-- Bottom Section: Social Links and Banner Image -->
    <div class="hero__bottom">
        <!-- Left: Social Links -->
        <div class="hero__social">
            <span>Encuéntranos en:</span>
            <a href="https://www.youtube.com/@coloniacompany" target="_blank">
                <img src="./../../../../assets/img/ico-youtube.png" alt="YouTube" class="hero__social-icon" />
            </a>
            <a href="https://www.linkedin.com/showcase/miraladata" target="_blank">
                <img src="./../../../../assets/img/ico-linkedin.png" alt="LinkedIn" class="hero__social-icon" />
            </a>
        </div>

        <!-- Right: Banner Image -->
        <div class="hero__image-container">
            <img [src]=" isMobile ? './../../../../assets/img/BANNER-MOBILE.png' :'./../../../../assets/img/BANNER-dsktop.png'" alt="Agente Inteligente" class="hero__image" />
        </div>
    </div>
</section>


<div class="section-container">
    <div class="section-header">
        <h1>¡Revoluciona tu Negocio con Mira 3.0!</h1>
        <p>
            Descubre cómo los Agentes Inteligentes Autónomos pueden transformar tus procesos, <br> optimizar resultados y ofrecer experiencias únicas a tus clientes.
        </p>
    </div>

    <div class="card-container">
        <!-- Primera tarjeta -->
        <mat-card class="info-card">
            <mat-icon class="card-icon">person</mat-icon>
            <h2>Product Manager</h2>
            <div class="card-info">
                <mat-icon>badge</mat-icon>
                <p>MSc. Julio Cesar Elias</p>
            </div>
            <div class="card-info">
                <mat-icon>email</mat-icon>
                <p><a href="mailto:julio.elias@colonia.compay">julio.elias&#64;colonia.company</a></p>
            </div>
            <div class="card-info">
                <mat-icon>phone</mat-icon>
                <p>+51 904448143</p>
            </div>
        </mat-card>

        <!-- Segunda tarjeta -->
        <mat-card class="info-card">
            <mat-icon class="card-icon">business</mat-icon>
            <h2>Contacto comercial en Perú</h2>
            <div class="card-info">
                <mat-icon>person</mat-icon>
                <p>Patricia Rojas</p>
            </div>
            <div class="card-info">
                <mat-icon>email</mat-icon>
                <p><a href="mailto:info@colonia.company">info&#64;colonia.company</a></p>
            </div>
            <div class="card-info">
                <mat-icon>phone</mat-icon>
                <p>+51 997569733</p>
            </div>
        </mat-card>

        <!-- Tercera tarjeta -->
        <mat-card class="info-card">
            <mat-icon class="card-icon">business</mat-icon>
            <h2>Contacto comercial en Colombia</h2>
            <div class="card-info">
                <mat-icon>person</mat-icon>
                <p>Adriana Ibarra</p>
            </div>
            <div class="card-info">
                <mat-icon>email</mat-icon>
                <p><a href="mailto:comercial@q-antica.com">comercial&#64;q-antica.com</a></p>
            </div>
            <div class="card-info">
                <mat-icon>phone</mat-icon>
                <p>+57 316 444 5686</p>
            </div>
        </mat-card>
    </div>
</div>