import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Title } from '@angular/platform-browser';
 
@Injectable({
   providedIn: 'root'
})
export class SEOService { 
 
  constructor(private title: Title, @Inject(DOCUMENT) private doc: any, @Inject(PLATFORM_ID) private plattformId: any) { 
    
  }

  setPageTitle(title: string) {
    this.title.setTitle(title);
  }
    
  updateCanonicalUrl(url: string){
    const hostName="https://miraladata.com/";
    if(isPlatformBrowser(this.plattformId)) {
        let link: HTMLLinkElement = this.doc.createElement('link');
        link.setAttribute('rel', 'canonical');
        this.doc.head.appendChild(link);
        link.setAttribute('href', hostName+url);
    }
  }
}  