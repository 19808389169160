<section class="sec-our-offices">
    <div class="title-our-offices">
        <h2>Nuestras oficinas</h2>
    </div>
    <div class="card-container">
        <div class="card-offices">
            <div class="img-offices"></div>
            <div class="offices-info">
                <div class="title">
                    <h3>América del sur</h3>
                </div>
                <div class="office">
                    <h5>Perú</h5>
                    <ul>
                        <li>
                            <mat-icon>place</mat-icon>
                            <span>Av. EL Derby 055. Edificio Cronos, Torre 1 - Piso 7, Santiago de Surco, Lima</span>
                        </li>
                        <li>
                            <mat-icon>business</mat-icon>
                            <span>Razón social: Colonia Company</span>
                        </li>
                        <li>
                            <mat-icon>phone_iphone</mat-icon>
                            <span><a href="tel:+51904448143" target="_blank">+51 904448143</a></span>
                        </li>
                        <li>
                            <mat-icon>email</mat-icon>
                            <span><a href="mailto:info@colonia.company" target="_blank">info&#64;colonia.company</a></span>
                        </li>
                    </ul>
                </div>
                <div class="office">
                    <h5>Colombia</h5>
                    <ul>
                        <li>
                            <mat-icon>place</mat-icon>
                            <span>Calle 90 N° 18-53. Oficina 102, Bogotá</span>
                        </li>
                        <li>
                            <mat-icon>business</mat-icon>
                            <span>Razón social: Q-antica S.A.S.</span>
                        </li>
                        <li>
                            <mat-icon>phone_iphone</mat-icon>
                            <span><a href="tel:+573102433751" target="_blank">+57 3102433751</a></span>
                        </li>
                        <li>
                            <mat-icon>email</mat-icon>
                            <span><a href="mailto:teresa.lopez@q-antica.com" target="_blank">teresa.lopez&#64;q-antica.com</a></span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>